@import url(https://fonts.googleapis.com/css2?family=Noto%20Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tajawal&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Vibes&display=swap);
* {
  box-sizing: border-box;
  font-family: "Noto Sans", Tajawal, 'Nanum Pen Script', cursive, Georgia, sans-serif, 'Times New Roman';
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  display: block;

  /* outline: inherit; */
}

:root {
  /* Colors */
  --gray-0: #171717;
  --gray-1: #333;
  --gray-2: #4f4f4f;
  --gray-3: #828282;
  --gray-4: #bdbdbd;
  --gray-5: #e0e0e0;
  --gray-6: #ececec;
  --gray-7: #f2f2f2;
  --gray-8: #f4f4f4;
  --gray-9: #f8f8f8;
  --gray-10: #f9f9f9;
  --blue-light: #5e8ab4;
  --blue-primary: #1060c1;
  --blue-dark: #0e55ac;
  --blue-lightest: #d8e5fd;
  --green-primary: #09814a;
  --red-primary: #9d061a;
  --yellow-primary: #d8b54b;

  /* Dimensions */
  --side-margin-mobile: 10px;
  --side-margin-desktop: 15px;
  --bottom-nav-height: 75px;
  --bottom-nav-main-menu-height: 62.5px;
  --view-max-width: 1000px;

  /* Border radius */
  --normal-border-radius: 5px;
  --medium-border-radius: 10px;
  --big-border-radius: 20px;

  /* Breakpoints */
  --mobile-small: 20em;
  --mobile-medium: 23.438em;
  --mobile-large: 25.875em;
  --tablet: 48em;
  --laptop: 68.75em;
  --laptop-large: 90em;
  --desktop: 160em;

  /* Font */
  --base-font-size: 18px;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  font-size: var(--base-font-size);
}

body {
  background-color: var(--gray-10);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
a,
p {
  margin: 0 0;
  line-height: 1.75;
  color: var(--gray-0);
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 24px;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] { font-size: var(--base-font-size); }

